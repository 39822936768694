<template>
  <v-app id="inspire">
    <router-view/>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    links: [
      {name: 'Home', to: 'Home'},
      {name: 'Dashboard', to: 'Dashboard'},
      {name: 'Messages', to: 'Messages'},
      {name: 'Profile', to: 'Profile'},
      {name: 'Updates', to: 'Updates'}
    ]
  }),
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

p, div, a, h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px!important;
  }
}

</style>
