import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../plugins/axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isLogged: false,
    event: null,
    events: [],
    snackBar: {
      state: false,
      message: '',
      color: 'success'
    },
    dialog: false
  },
  mutations: {
    SET_EVENTS(state, event) {
      state.events = event
    },
    REMOVE_EVENT(state) {
      state.event = null
    },
    ADD_EVENT(state, event) {
      state.events.push(event)
    },
    SET_USER(state, user) {
      state.user = user
      state.isLogged = true
    },
    REMOVE_USER(state) {
      state.user = null
      state.isLogged = false
    }
  },
  actions: {
    LOGIN_USER({dispatch}, data) {
      return axios.post('/user/login', data)
        .then(res => {
          localStorage.setItem('meet-token', res.data.access_token);
          dispatch('GET_PROFILE')
          return res
        })
        .catch(err => {
          return Promise.reject(err)
        })
    },
    GET_PROFILE({commit}) {
      return axios.get('/user/me')
        .then(res => {
          commit('SET_USER', res.data)
        })
    },
  },
  modules: {
  }
})
