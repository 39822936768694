import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        //primary: colors.lightGreen.lighten3, // #E53935
        primary: '#0A32E6',
        secondary: '#FED08E', // #FFCDD2
        accent: colors.indigo.base, // #3F51B5
        gray: colors.grey.lighten2, // #3F51B5
      }
    }
  }
});
