import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/meet',
    name: 'Meet',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Meet.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    roles: ['admin'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
  },
  {
    path: '/events',
    name: 'Events',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
  },
  {
    path: '/register',
    name: 'Register',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/users',
    name: 'Users',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue')
  },
  {
    path: '/account',
    name: 'Account',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue')
  },
  {
    path: '/meet/:id/timeslot/:timeslotId',
    name: 'Meet-Id-Timeslot-Id',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/meet/_id/timeslot/_id.vue')
  },
  {
    path: '/meet/:id/users',
    name: 'Meet-Id-Users',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/meet/_id/Users.vue')
  },
  {
    path: '/speakers',
    name: 'Speakers',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Speakers.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    roles: ['*'],
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword')
  },
  {
    path: '/slots',
    name: 'Slots',
    roles: ['admin', 'speaker'],
    component: () => import(/* webpackChunkName: "about" */ '../views/Slots')
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let route = routes.find(i => i.name == to.name)

  if(route.roles.includes('*')) {
    next()
  }
  else if(store.state.isLogged) {
    if(route.roles.includes(store.state.user.type)) {
      next()
    }
  }
  else if(!store.state.isLogged) {
    store.dispatch('GET_PROFILE').then(res => {
      if(route.roles.includes(store.state.user.type)) {
        next()
      }
      else {
        next({ name: 'Home' })
      }
    }).catch(() => {
      next({ name: 'login' })
    })
  }
  else {
    next()
  }
})

export default router
