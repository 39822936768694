import axios from 'axios'
import cfg from '../config'

let instance = axios.create({baseURL: cfg.URL});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('meet-token');
  if(token) {
    config.headers.authorization = 'Bearer ' + token;
  }
  return config;
});

export default instance;
